import {
  type ClientLoaderFunctionArgs,
  json,
  redirect,
  useLoaderData,
} from '@remix-run/react';

import { fetchAuthVerify } from '../../app/fetches/fetchAuthVerify';
import { deriveEscapeData, InAppEscape } from '../components/InAppEscape';
import { isGuest } from '../types/user';
import {
  deleteKnownRedirectParam,
  getKnownRedirectParam,
  setKnownRedirectParam,
} from '../utils/redirect-to';
import {
  isForbidden,
  isNotFound,
  tokenWithRedirect,
  type TokenWithRedirectOpts,
} from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';
import { clearToken } from '../utils/token';

// The intent of this route is that a game-pack can be "distributed", implying
// that we care about the learner's result, likely via an org-id. This is
// distinct from "/share" because that is more about testing or masquerading as
// an organization. Addtionally, having a separate URL avoids needing to define
// a cascade of url params and how they interact. What if both org-id and
// as-org-id were set?

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);

  // NOTE: if you add a param here, you'll probably want to clean it up before
  // redirecting to the overworld!
  const targetOrgId = getKnownRedirectParam(url.searchParams, 'org-id');
  const learnerId = getKnownRedirectParam(url.searchParams, 'learner-id');
  const learnerName = getKnownRedirectParam(url.searchParams, 'learner-name');

  const inAppData = deriveEscapeData(url);
  if (inAppData) return json(inAppData);

  const id = action.params.id;
  if (!id) {
    throw new Error('expected gamepack id');
  }

  try {
    // if learner-id is email-like (there is a slim chance, depending on the
    // SCORM environment) or learner-name is set, pass them into the
    // registration/login form to save some typing. These `params` are only used
    // in the event of a redirect.
    const params = new URLSearchParams();
    const email = learnerId?.includes('@') && (learnerId ?? null);
    setKnownRedirectParam(params, 'email', email);
    setKnownRedirectParam(params, 'name', learnerName ?? null);
    setKnownRedirectParam(params, 'org-id', targetOrgId ?? null);

    const opts: TokenWithRedirectOpts = {
      requireAuthentication: true,
      preferRedirect: targetOrgId ? 'register-into-org' : 'login',
      params,
    };

    // Throws when redirecting...
    const resp = await tokenWithRedirect(
      fetchAuthVerify,
      action.request.url,
      opts
    );

    // If the user has previously tried a course via a /share link and has a
    // guest token, ensure it's cleared out and try again. This will force
    // the user into the register-into-org flow.
    if (isGuest(resp.user)) {
      // Clear localStorage...
      clearToken();
      // Clear in-memory...
      setAPIServiceClientSecureToken(true);
      await tokenWithRedirect(fetchAuthVerify, action.request.url, opts);
    }

    // redirect to the overworld
    const destination = new URL(action.request.url);
    destination.pathname = `/game-packs/${id}/overworld`;
    deleteKnownRedirectParam(destination.searchParams, 'org-id');
    deleteKnownRedirectParam(destination.searchParams, 'learner-id');
    deleteKnownRedirectParam(destination.searchParams, 'learner-name');
    throw redirect(destination.toString());
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }
};

export function Component() {
  const escapeData = useLoaderData<typeof clientLoader>();
  return <InAppEscape escapeData={escapeData} />;
}
